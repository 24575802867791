import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
import Check from '@material-ui/icons/Check';
import Cross from '@material-ui/icons/Clear';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "effective-problem-solving"
    }}>{`Effective problem-solving`}</h2>
    <h3 {...{
      "id": "3-evaluate-the-possible-solutions"
    }}>{`3. Evaluate the possible solutions.`}</h3>
    <p>{`Together with your teenager, review your list of ideas. Look at the positives and negatives of each idea. See if some solutions could be adapted or combined to make them even better.`}</p>
    <p>{`Eliminate those ideas that are:`}</p>
    <ul>
      <li parentName="ul">{`Less likely to work well.`}</li>
      <li parentName="ul">{`Too hard to put into action.`}</li>
      <li parentName="ul">{`Unrealistic due to constraints such as time, money or resources.`}</li>
    </ul>
    <p>{`Encourage your teenager to think about the effects of each possible solution on other people.`}</p>
    <ul>
      <li parentName="ul">{`How will other people be affected?`}</li>
    </ul>
    <p>{`At this stage, it is important to evaluate all the ideas and encourage your teenager to fully take part. Finally, number the remaining ideas in order of preference.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Possible solutions`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Get a Maths tutor.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too expensive `}<Cross mdxType="Cross" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Do Maths exercises each night as homework.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`02 `}<Check mdxType="Check" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Get extra help from the teacher.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The teacher is not very approachable `}<Cross mdxType="Cross" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Have a study partner.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`01 `}<Check mdxType="Check" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ask for study guides.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Combine with study partner `}<Cross mdxType="Cross" /></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      